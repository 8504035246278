import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useServicesList() {
    const toast = useToast()
    const refListTable = ref();
    let blankData = {
        start_date:new Date().toISOString().slice(0,10),
        end_date:new Date().toISOString().slice(0,10),
      
    }
    const filterData = ref(JSON.parse(JSON.stringify(blankData)));
    const pageLength = ref(10);
    const currentPage = ref(1);
    const Total = ref(0);
    const pricetotal = ref(0);
    const items = ref([]);
    const tableColumns = [
        // { field: 'no', label: '#', sortable: false},
         { field: 'code_member', label: 'CN', sortable: false},
         { field: 'name', label: 'ชื่อ - สกุล', sortable: false},
         { field: 'detail', label: 'Detail', sortable: false},
         { field: 'price', label: 'ราคา (บาท)', sortable: false},
         { field: 'date', label: 'วันที่', sortable: false},
      
 
 
 
    ]
    const itemsPrint = ref([]);
    const priceprinttotal = ref();
    watch([currentPage, pageLength], () => {
        items.value = []
        fetchData();
    })
    const fetchData = async ()=>{
        //assistant
        let page = currentPage.value;
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const offset = pageLength.value*(page-1);
        pricetotal.value = '0.00';
       
        console.log('currentPage',currentPage.value);
        
        let response =  await store.dispatch('app-report/fetchServicesData', {
                // id:queue_id,
                  limit: pageLength.value,
                  offset:offset,
                  branch_id:userStorage.branch_id,
                  start_date:filterData.value.start_date,
                  end_date:filterData.value.end_date,
                  types:'receipt',
                  order_by:' price_total DESC'
                
                
                  
              
                });
          if(response.data.success){
            items.value = response.data.data;
            Total.value = response.data.total;
            console.log('a', items.value);
         
            pricetotal.value = response.data.pricetotal
  
          }else{
            Total.value = 0;
          }
         
        
  
    }
    fetchData();
    const ApplyFilter = async()=>{
        items.value = []
        fetchData();

    }
    const clearFilter = ()=>{
        items.value = []
        fetchData();

    }
    const getPrintData = async()=>{
        const userStorage = JSON.parse(localStorage.getItem('userData'));
            itemsPrint.value = []
            let response =  await store.dispatch('app-report/fetchServicesData', {
                // id:queue_id,
              
                branch_id:userStorage.branch_id,
                start_date:filterData.value.start_date,
                end_date:filterData.value.end_date,
                types:'receipt',
                order_by:' price_total DESC'
                
                
            
                });
            
        if(response.data.success){
            itemsPrint.value = response.data.data;
       
        

        }

      }
    //filterData
    return {
        filterData,
        ApplyFilter,
        clearFilter,
        tableColumns,
        items,
        refListTable,
        pageLength,
        currentPage,
        Total,
        pricetotal,
        getPrintData,
        itemsPrint,
        priceprinttotal
        
    }
}