<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row >
                    <b-col
                    md="6"
                    class="d-flex justify-content-start"
                    
                    >
                    <h4 class="text-dark font-weight-bold">Filter</h4>
                    
                    </b-col>
                    <b-col
                    md="6"
                    class="d-flex justify-content-end"
                    >
                    <b-button  
                        type="button"
                        class="ml-1"
                        variant="success"
                        @click="generateReport()"
                            >
                        
                            Print
                        </b-button>
                        <b-button  
                        type="button"
                        variant="primary"
                        class="ml-1"
                        @click="ApplyFilter"
                            >
                            
                            Apply
                        </b-button>
                        <b-button  
                        type="button"
                        class="ml-1"
                        variant="danger"
                        @click="clearFilter"
                            >
                        
                            Clear
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="form-boder-input">
                    <b-col md="3">
                       
                        <div class="demo-vertical-spacing ml-1">
                                <b-form-group
                                label="จากวันที่"
                                label-for="start_date"
                                >
                                <b-form-datepicker
                                    v-model="filterData.start_date"
                                    reset-button

                                    type="date"
                                />
                                </b-form-group>
                            </div>
                    
                    </b-col>
                    <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                               <b-form-group
                               label="ถึงวันที่"
                               label-for="end_date"
                               >
                               <b-form-datepicker
                                   v-model="filterData.end_date"
                                   reset-button

                                   type="date"
                               />
                               </b-form-group>
                           </div>
                   
                   </b-col>
                
                </b-row>
            </div>
        </b-card>
        <b-card title="รายงานผู้บริการหัตถการ" >
            <table class="vgt-table bordered ">
                <thead>
                    <tr>
                      
                        <th style="width:200px;" >CN</th>       
                        <th style="width:20%">ชื่อ - สกุล</th>
                    
                        <th style="width:40%">รายละเอียด</th>
                        <th>วันที่</th>
                    
                       
                    </tr>
                   
                </thead>
                <tbody>
                    <template v-for="(item,index) in items" >
                       
                      
                            <tr :key="'item'+'-'+index">
                         
                                <td >{{  item.code_member }}</td>
                                <td >{{  item.first_name }} {{  item.last_name }}</td>
                                <td  >{{  item.course_name }}</td>
                                <td  >{{  formatDateSlashA(item.date) }}</td>
                               
                      
                                
                            </tr>

                      
                       
                    </template>
                      

                    </tbody>
                   
                   
            </table>
            <b-pagination
                            v-model="currentPage"
                            :total-rows="Total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
           
                    
        </b-card>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        margin="5"
        filename="report.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :paginate-elements-by-height="2340"
      
        @hasDownloaded="attemptPrint($event)"
        @beforeDownload="beforeDownload($event)"
        @startPagination="startPagination($event)"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration($event)"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        >
        <section slot="pdf-content" class="pdf-content  text-black p-1">
            <div class=" boder-head-top">
                <div class="text-center">
                    <h1 class="text-black">รายงานผู้บริการหัตถการ</h1>

                </div>
                <div class="d-flex justify-content-center align-items-center">
                            <div>รายงานระหว่างวันที่</div>
                            <div class="head-date">{{  filterData.start_date?formatDateSlash(filterData.start_date):'-'  }}</div>
                            <div>ถึงวันที่</div>
                            <div class="head-date">{{  filterData.end_date?formatDateSlash(filterData.end_date):'-'  }}</div>
                </div>
            </div>
        <table class="table ">
                <thead>
                    <tr>
                        
                        <th style="width:200px;" >CN</th>       
                        <th style="width:20%">ชื่อ - สกุล</th>
                    
                        <th style="width:40%">รายละเอียด</th>
                        <th>วันที่</th>
                    
                       
                       
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item,index) in itemsPrint" >
                       
                      
                            <tr :key="'item-print'+'-'+index">
                                
                                   
                                <td >{{  item.code_member }}</td>
                                <td >{{  item.first_name }} {{  item.last_name }}</td>
                                <td  >{{  item.course_name }}</td>
                                <td  >{{  formatDateSlashA(item.date) }}</td>
                      
                      
                                
                            </tr>

                      
                       
                    </template>
                      

                    </tbody>
                   
                   
            </table>
           
                <!--<section class="pdf-item p-1"> 
                    <div class="d-flex justify-content-center align-items-center boder-head">
                        <div>รายงานยอดขายระหว่างวันที่</div>
                        <div class="head-date">{{  filterData.start_date?formatDateSlash(filterData.start_date):'-'  }}</div>
                        <div>ถึงวันที่</div>
                        <div class="head-date">{{  filterData.end_date?formatDateSlash(filterData.end_date):'-'  }}</div>
                    </div>
                    
                </section>-->
           
            
            <!--<div class="footer-pdf">
                D
               
            </div>-->
        </section>
        </vue-html2pdf>
    </div>
    
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateSlash,formatDateTh,formatDateToMonthShort,getCommaDecimal,getComma,formatDateSlashA } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
import VueHtml2pdf from 'vue-html2pdf'
import reportStoreModule from '../reportStoreModule'
import { join } from 'postcss-rtl/lib/affected-props'
import useServicesList from './useServicesList'
import patientsStoreModule from '../../patients/patientsStoreModule'

export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable,
    vSelect,
    VueHtml2pdf

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getCommaDecimal,
            getComma,
            formatDateSlash,
            formatDateSlashA
        }
    },
    computed: {
        allgateways(gateways) {
            let gateway = '';
            if(gateways.length > 0){
                let gateways =  gateways.map(item=>{
                    return item.title;
                });
                gateway = gateways.join(',');
                

            }
      

            return gateway;
        },
        alltotal() {
            let total = 0;
             this.items.map(item=>{
                total += (item.total*1);
            });
            return total+'.00';
        },
        alltotalPrint() {
            let total = 0;
             this.itemsPrint.map(item=>{
                total += (item.total*1);
            });
            return total+'.00';
        },
    },
    setup(props, { emit }){
        const REPORT_STORE_MODULE_NAME = 'app-report';
        if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
        onUnmounted(() => {
            if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
        });
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });

        
        const {
            filterData,
            ApplyFilter,
            clearFilter,
            tableColumns,
            items,
            refListTable,
            pageLength,
            currentPage,
            Total,
            pricetotal,
            getPrintData,
            itemsPrint,
            priceprinttotal
        } = useServicesList();
        return {
            filterData,
            ApplyFilter,
            clearFilter,
            tableColumns,
            items,
            refListTable,
            pageLength,
            currentPage,
            Total,pricetotal,
            getPrintData,
            itemsPrint,
            priceprinttotal
        }
    },
    methods:{
        renderSuggestion(suggestion){
           
            return suggestion.item.first_name+' '+suggestion.item.last_name
        },
         async generateReport () {
            console.log('generateReport');
            await this.getPrintData();
           
            
           this.$refs.html2Pdf.generatePdf();
        },
       async onProgress(event){
            console.log('onProgress',event);
           // console.log('event', event);
        },
        async hasStartedGeneration(){
            console.log('hasStartedGeneration');
           
        },
        async startPagination(event){
            console.log('startPagination',event);
            

        },
        async hasGenerated(){
            console.log('hasGenerated');
        },
       
        attemptPrint(blob) {
            console.log('attemptPrint',blob);
       // let url = URL.createObjectURL(blob);
        //window.open(url); //opens the pdf in a new tab
      },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            console.log('beforeDownload',options);
            options.margin = [0.7, 0.1, 0.7, 0.1];
           
          //  console.log('options',options);
        //    console.log('pdfContent',pdfContent);
            
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                var dt = new Date();
                var year  = dt.getFullYear();
                var month = (dt.getMonth() + 1).toString().padStart(2, "0");
                var day   = dt.getDate().toString().padStart(2, "0");
                let date = day+'/'+month+'/'+year
                const totalPages = pdf.internal.getNumberOfPages()
                var text = "Viva Clinic";
               // var xOffset = (pdf.internal.pageSize.getWidth() * 0.88) - (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / 2);
                var xOffset = (pdf.internal.pageSize.getWidth()/2);
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setFontSize(14)
                    //pdf.fromHTML('<div>test</div>');
                    pdf.setTextColor('#000000')
                    pdf.text(text, xOffset, (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)),{align: 'center'})
                    pdf.text(date, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)))
                    pdf.line(10,10,(pdf.internal.pageSize.getWidth() * 0.88),20,'S')
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    
                } 
              
            }).output('bloburl').then(r => { window.open(r) });
           
         // this.$refs.html2Pdf.generatePdf()
        }
    },
    
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.pdf-page{
    position: relative;
 
}
.table{
    td{
        color:#000 !important;
    }
}
.text-total{
    font-weight:400; font-size:24px; 
    u,span{
        color:#000;
    }
    color:#000;
}
.footer-pdf{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}
.boder-head-top{
    border-top:2px solid #000;
  
  
    font-size: 20px;
    padding: 10px;
}
.content-bottom{
    font-size: 20px;
    border-bottom:2px solid #000;
    padding: 10px;
}
.border-b{
    border-bottom:1px solid #ccc;
    width: 75%;
    margin-left:auto ;
    padding: 8px;
    font-size: 16px;
}
.head-date{
    width: 200px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
}
.pdf-content{
    font-family: "Helvetica";
}
.pdf-content-footer{
    width: 80%;
    margin-left:auto ;

}
</style>