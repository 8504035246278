import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchStocksData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/stocks',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchDashBoardData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/dashboard',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchfeeData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/fee',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchSalesData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/sales',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchServicesData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/services',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchByDateData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/bydate',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchByNameData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/byname',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchSalesDailyData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/sales/daily',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchSalesTypesData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .post('/report/v1/sales/types',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        
        updatefeestatusData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
                
            return new Promise((resolve, reject) => {
                axios
                .put('/report/v1/fee/updatestatus/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        }
        
    }
}